




























































































































import Vue from 'vue';
export default Vue.extend({
  name: 'SessionStats',
  props: {
    stats: {
      type: Array,
      required: true,
    },
  },
});
