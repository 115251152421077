






































import Vue from 'vue';
import ThemeModel from '@/dtos/theme';
export default Vue.extend({
  name: 'WordTable',
  props: {
    words: {
      type: Array,
      default: () => [],
      required: true,
    },

    theme: {
      type: Object,
      required: false,
      default: () => ThemeModel.defaultTheme(),
    },
  },
});
