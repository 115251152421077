























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PieChart from '@/components/Presenter/SessionReport/PieChart.vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import PollResultsWithTextField from '@/components/Presenter/PollResultsWithTextField.vue';
export default Vue.extend({
  components: {
    PieChart,
    PollResults,
    PollResultsWithTextField,
  },
  data() {
    return {
      pollTypes: ['Singlechoice', 'Multiplechoice '],

      options: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: 'rgb(255, 255, 255)',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      colorSelection: [
        'rgba(103,237,184,1)',
        'rgba(82,219,194,1)',
        'rgba(62,200,210,1)',
        'rgba(41,182,227,1)',
        'rgba(21, 164,240,1)',
        'rgba(2,133,253,1)',
        'rgba(6,96,243,1)',
        'rgba(11, 48, 233,1)',
        'rgba(16, 0, 222,1)',
      ],
    };
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
    }),
    computedPollType(): string {
      return this.pollTypes[this.poll.type];
    },
    computedContainsTextField(): string {
      return this.poll.textFieldPlaceholder != '' ? 'Ja' : 'Nein';
    },
    computedAnswersCount(): number {
      return Object.keys(this.poll.answers).length;
    },
    computedData(): number[] {
      const data: number[] = [];
      this.poll.choices.map((choice: string, i: number) => {
        data.push(this.calcAbsForAnswers(this.poll.answers, i)['absCount']);
      });

      return data;
    },
    computedChartData(): any {
      return {
        labels: this.poll.choices,
        datasets: [
          {
            borderWidth: 1,
            borderColor: this.pickColors(this.poll.choices.length),
            backgroundColor: this.pickColors(this.poll.choices.length),
            data: this.computedData,
          },
        ],
      };
    },
  },
  methods: {
    calcAbsForAnswers(
      answers: any[],
      choice: number
    ): { absCount: number; sum: number } {
      let absCount = 0;
      let sum = 0;
      if (answers) {
        Object.values(answers).map((answer: any) => {
          if (answer instanceof Array) {
            sum += answer.length;
            if (answer.includes(choice)) {
              absCount += 1;
            }
          } else {
            sum += 1;
            if (answer === choice) {
              absCount += 1;
            }
          }
        });
      }
      return { absCount, sum };
    },
    pickColors(n: number): string[] {
      const colors: string[] = [];
      for (let i = n; i--; ) {
        colors.push(this.colorSelection[i % 9]);
      }
      return colors;
    },
    csvExport() {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [
        this.poll.title.replace(/,/g, ' ').replace(/(?:\r\n|\r|\n)/g, ' '),
        ...Object.values(this.poll.answers).map((a: any) =>
          typeof a[0] === 'string'
            ? `${a[0].replace(/,/g, ' ').replace(/(?:\r\n|\r|\n)/g, ' ')}`
            : this.poll.choices[a[0]]
        ),
      ].join('\n');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute(
        'download',
        `${this.currentSession.name}-Modul-Umfragen.csv`
      );
      link.click();
    },
  },
});
