





















import Vue from 'vue';
import { mapGetters } from 'vuex';
import Pagination from '@/components/Presenter/SessionReport/Pagination.vue';
import { formatDate } from '@/utils/tools';

export default Vue.extend({
  components: {
    Pagination,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    await this.$store.dispatch('viewer/fetchSessionTheme', {
      sessionUserGroup: this.currentSession.userGroup,
    });
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
    }),
  },
  methods: {
    formatDate(input: any): string {
      return formatDate(input);
    },
  },
});
