
























































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import ThemeModel from '@/dtos/theme';
export default Vue.extend({
  name: 'PollResults',
  mounted() {
    if (this.pollId) {
      this.folded =
        localStorage.getItem(`poll-folded-${this.pollId}`) === 'true';
    }
  },
  data() {
    return {
      folded: false,
    };
  },
  props: {
    choices: {
      type: Array,
      default: () => [],
      required: true,
    },
    pollId: {
      type: String,
      default: '',
      required: true,
    },
    theme: {
      type: Object,
      required: false,
      default: () => ThemeModel.defaultTheme(),
    },
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFilterPoll: {
      type: Boolean,
      default: false,
      required: false,
    },
    filteredPollAnswer: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getPollAnswers: 'presenter/getPollAnswers',
    }),
    pollAnswer(): any {
      if (this.pollId in this.getPollAnswers) {
        return this.getPollAnswers[this.pollId];
      }
      return {};
    },
  },
  methods: {
    toggleFolded(state: boolean): void {
      this.folded = state;
      // Save folded to localStorage
      if (this.pollId) {
        localStorage.setItem(`poll-folded-${this.pollId}`, state.toString());
      }
    },
  },
});
