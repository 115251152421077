





















































































































































































import Vue from 'vue';
export default Vue.extend({
  props: {
    reactionName: {
      type: String,
      required: true,
    },
    reactionColor: {
      type: String,
      required: true,
    },
    aligned: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
