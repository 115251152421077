
import Vue from 'vue';
import { Pie } from 'vue-chartjs';
export default Vue.extend({
  extends: Pie,
  props: {
    options: {
      type: Object,
      required: false,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    const self = this as any;
    self.renderChart(self.chartData, this.options);
  },
});
