




















































































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
export default Vue.extend({
  props: {
    questions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
  },
});
