



































































import Vue from 'vue';
export default Vue.extend({
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
  },
});
