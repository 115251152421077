import Vue from 'vue';
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default Vue.extend({
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      required: false,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    const self = this as any;
    self.renderChart(self.chartData, this.options);
  },
});
