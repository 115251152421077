































import Vue from 'vue';
import { mapGetters } from 'vuex';

import ReportWrapper from '@/components/Presenter/SessionReport/ReportWrapper.vue';
import SessionStats from '@/components/Presenter/SessionReport/SessionStats.vue';
import ReactionBarGraph from '@/components/Presenter/ReactionBarGraph.vue';
import TimelineGraph from '@/components/Presenter/TimelineGraph.vue';
import { formatDateString } from '@/utils/tools';

export default Vue.extend({
  components: {
    ReactionBarGraph,
    TimelineGraph,
    SessionStats,
    ReportWrapper,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    await this.$store.dispatch('viewer/fetchSessionTheme', {
      sessionUserGroup: this.currentSession.userGroup,
    });
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      questions: 'questions/getAllQuestions',
      polls: 'presenter/getSessionPolls',
      wordclouds: 'presenter/getSessionWordclouds',
    }),

    computedReactionsCount(): number {
      let countReactions = 0;
      if (this.currentSession) {
        if ('reactions' in this.currentSession) {
          for (const count of Object.values(this.currentSession.reactions)) {
            countReactions += count as number;
          }
        }
      }
      return countReactions;
    },
    computedActiveUsers(): number {
      if (this.sessionViewers) {
        return this.sessionViewers.length;
      }
      return 0;
    },
    computedDataCollection(): any {
      const labels = [0, 5, 10, 20];
      const datacollection = {
        labels,
        datasets: [
          {
            label: this.$t('views.presenter.session.reportHomeReactions'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: [0, 5, 10, 20],
            borderColor: '#67EDB8',
          },
          {
            label: this.$t('views.presenter.session.reportHomeActiveUser'),
            backgroundColor: 'transparent',
            pointStyle: 'line',
            data: labels.map((i) => 0.5 * Math.sin(i / 5)),
            borderColor: '#80F5FF',
          },
        ],
      };
      return datacollection;
    },
    computedReactions(): any {
      if (this.currentSession && this.currentSession.reactions) {
        const { reactions } = this.currentSession;
        return [
          {
            name: 'thumbs-up',
            imageName: 'reaction-thumbs-up.png',
            color: '#0B30E9',
            value: reactions.thumbsUpCount,
            fillLevel:
              (reactions.thumbsUpCount / this.computedReactionsCount) * 100,
            label: this.$t('views.thumbsUp'),
          },

          {
            name: 'funny',
            imageName: 'reaction-funny.png',
            color: '#0660F3',
            value: reactions.funEmojiCount,
            fillLevel:
              (reactions.funEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.laughing'),
          },
          {
            name: 'sad',
            imageName: 'reaction-sad.png',
            color: '#0092FF',
            value: reactions.sadEmojiCount,
            fillLevel:
              (reactions.sadEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.crying'),
          },
          {
            name: 'claps',
            imageName: 'reaction-claps.png',
            color: '#15A4F0',
            value: reactions.clapsCount,
            fillLevel:
              (reactions.clapsCount / this.computedReactionsCount) * 100,
            label: this.$t('views.clapping'),
          },
          {
            name: 'rocket',
            imageName: 'reaction-rocket.png',
            color: '#29B6E3',
            value: reactions.rocketCount,
            fillLevel:
              (reactions.rocketCount / this.computedReactionsCount) * 100,
            label: this.$t('views.rocket'),
          },
          {
            name: 'love',
            imageName: 'reaction-love.png',
            color: '#3EC8D2',
            value: reactions.heartCount,
            fillLevel:
              (reactions.heartCount / this.computedReactionsCount) * 100,
            label: this.$t('views.loveIt'),
          },
        ];
      }
      return [];
    },
    computedStartDate(): string {
      if (this.currentSession.startAt) {
        return formatDateString(this.currentSession.startAt.toDate());
      }
      return formatDateString(null);
    },
    computedEndDate(): string {
      if (this.currentSession.endAt && !this.currentSession.sessionUnlimited) {
        return formatDateString(this.currentSession.endAt.toDate());
      }
      return formatDateString(null);
    },
    computedFirstColStats(): any {
      return [
        {
          label: this.$t('views.presenter.session.reportHomeUserInSession'),
          value: this.computedActiveUsers,
          iconName: 'users',
        },
        {
          label: this.$t('views.presenter.session.reportHomeAllReactions'),
          value: this.computedReactionsCount,
          iconName: 'reactions',
        },
      ];
    },
    computedSecondColStats(): any {
      return [
        {
          label: this.$t('views.presenter.session.reportHomeAllQuestions'),
          value: this.questions ? this.questions.length : 0,
          iconName: 'questions',
        },
        {
          label: this.$t('views.presenter.session.reportHomeAllPolls'),
          value: this.polls ? this.polls.length : 0,
          iconName: 'polls',
        },
        {
          label: this.$t('views.presenter.session.reportHomeAllWordclouds'),
          value: this.wordclouds ? this.wordclouds.length : 0,
          iconName: 'wordcloud',
        },
      ];
    },
  },
});
