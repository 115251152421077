










import Vue from 'vue';
import LineChart from '../LineChart';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { LineChart },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  async mounted() {
    await this.$store.dispatch('presenter/bindTimelineData', this.sessionId);
  },
  computed: {
    ...mapGetters({
      timelineData: 'presenter/getTimelineData',
    }),
    data(): any {
      if (this.timelineData) {
        const labels = [];
        const reactionSums = [];
        const sortedTimelineDate = this.timelineData;
        sortedTimelineDate.sort(
          (a: any, b: any) => parseInt(a.id) > parseInt(b.id)
        );
        for (const entry of sortedTimelineDate) {
          labels.push(entry['id']);
          let reactionsSum = 0;
          for (const count of Object.values(entry['reactions'])) {
            reactionsSum += count as number;
          }
          reactionSums.push(reactionsSum);
        }
        return {
          labels: labels,
          datasets: [
            {
              label: this.$t('components.presenter.scaffoldNew'),
              fill: false,
              borderColor: '#0655F4',
              data: reactionSums,
            },
          ],
        };
      }
      return {};
    },
  },
  methods: {},
});
