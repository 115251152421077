














































import Vue from 'vue';
import { mapGetters } from 'vuex';

import ReportWrapper from '@/components/Presenter/SessionReport/ReportWrapper.vue';
import QuestionsTable from '@/components/Presenter/SessionReport/QuestionsTable.vue';

export default Vue.extend({
  components: {
    ReportWrapper,
    QuestionsTable,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    await this.$store.dispatch('viewer/fetchSessionTheme', {
      sessionUserGroup: this.currentSession.userGroup,
    });
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      questions: 'questions/getHiddenQuestions',
    }),
  },
  methods: {
    csvExport(arrData: any) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [
        'ID;Frage;Absender;Antwort;Upvotes',
        ...arrData.map(
          (q: any) =>
            `${q.initialPosition};${q.title
              .replace(/,/g, ' ')
              .replace(/(?:\r\n|\r|\n)/g, ' ')};${
              q.questionner || 'anonym'
            };${q.answer.replace(/,/g, ' ').replace(/(?:\r\n|\r|\n)/g, ' ')};${
              q.upvotes
            }`
        ),
      ].join('\n');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute(
        'download',
        `${this.currentSession.name}-Modul-Fragen-unsichtbar.csv`
      );
      link.click();
    },
  },
});
