
























import Vue from 'vue';
import { mapGetters } from 'vuex';

import ReportWrapper from '@/components/Presenter/SessionReport/ReportWrapper.vue';
import PollResult from '@/components/Presenter/SessionReport/PollResult.vue';

export default Vue.extend({
  components: {
    ReportWrapper,
    PollResult,
  },
  data() {
    return {
      now: new Date(),
      selectedTabItem: 0,
      qrCodeURL: '',
      showEmbedModal: false,
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPresenterSession',
      presenterSessionId
    );
    await this.$store.dispatch('viewer/fetchSessionTheme', {
      sessionUserGroup: this.currentSession.userGroup,
    });
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      polls: 'presenter/getSessionPolls',
    }),
  },
});
