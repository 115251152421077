var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.embedded ? 'flex flex-col space-y-4 mt-3' : 'flex flex-col space-y-4 mt-3'},[(_vm.choices.length > 6)?_c('div',[(_vm.folded)?_c('button',{staticClass:"text-white underline",on:{"click":function($event){return _vm.toggleFolded(false)}}},[_vm._v(" Ausklappen ")]):_c('button',{staticClass:"text-white underline",on:{"click":function($event){return _vm.toggleFolded(true)}}},[_vm._v(" Einklappen ")])]):_vm._e(),(!_vm.folded)?_vm._l((_vm.choices),function(choice,j){return _c('div',{key:j,staticClass:"flex flex-row w-full space-x-5 max-w-xl items-center"},[(!_vm.isFilterPoll)?[_c('div',{staticClass:"z-0 bg-white opacity-95 h-16 flex justify-between items-center px-1 rounded-lg",style:({
            width: ((_vm.pollAnswer['choices']
                ? _vm.pollAnswer['choices'][choice.id].percentage
                : 0) + "%"),
          })}),_c('h4',{staticClass:"text-white"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.pollAnswer['choices'] ? _vm.pollAnswer['choices'][choice.id].percentage : 0)+"% ")]),_c('span',{staticClass:"text-lg font-bold"},[_vm._v(_vm._s(choice.value))]),_c('br'),_vm._v(" "+_vm._s(_vm.pollAnswer['choices'] ? _vm.pollAnswer['choices'][choice.id].count : 0)+" Votes ")])]:_vm._e(),(_vm.isFilterPoll)?[_c('div',{staticClass:"z-0 bg-white opacity-95 h-16 flex justify-between items-center px-1 rounded-lg",style:({
            width: ((choice.id in _vm.filteredPollAnswer
                ? _vm.filteredPollAnswer[choice.id].percentage
                : 0) + "%"),
          })}),_c('h4',{staticClass:"text-white"},[_c('span',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(choice.id in _vm.filteredPollAnswer ? _vm.filteredPollAnswer[choice.id].percentage : 0)+"% ")]),_c('span',{staticClass:"text-lg font-bold"},[_vm._v(_vm._s(choice.value))]),_c('br'),_vm._v(" "+_vm._s(choice.id in _vm.filteredPollAnswer ? _vm.filteredPollAnswer[choice.id].count : 0)+" Votes ")])]:_vm._e()],2)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }