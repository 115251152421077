




















import Vue from 'vue';
import ReactionBarItem from './ReactionBarItem.vue';
export default Vue.extend({
  components: { ReactionBarItem },
  props: {
    reactions: {
      type: Array,
      default: () => [],
      required: true,
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    renderAnimation: { type: Boolean, required: false, default: false },
    animationName: { type: String, required: false, default: '' },
  },
});
